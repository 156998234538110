import { LRLanguage, indentNodeProp, delimitedIndent, foldNodeProp, foldInside, LanguageSupport } from '@codemirror/language';
import { styleTags, tags } from '@lezer/highlight';
import { LRParser } from '@lezer/lr';

// This file was generated by lezer-generator. You probably shouldn't edit it.
const spec_Keyword = {__proto__:null,anyref:34, dataref:34, eqref:34, externref:34, i31ref:34, funcref:34, i8:34, i16:34, i32:34, i64:34, f32:34, f64:34};
const parser = /*@__PURE__*/LRParser.deserialize({
  version: 14,
  states: "!^Q]QPOOOqQPO'#CbOOQO'#Cd'#CdOOQO'#Cl'#ClOOQO'#Ch'#ChQ]QPOOOOQO,58|,58|OxQPO,58|OOQO-E6f-E6fOOQO1G.h1G.h",
  stateData: "!P~O_OSPOSQOS~OTPOVROXROYROZROaQO~OSUO~P]OSXO~P]O",
  goto: "xaPPPPPPbPbPPPhPPPrXROPTVQTOQVPTWTVXSOPTV",
  nodeNames: "⚠ LineComment BlockComment Module ) ( App Identifier Type Keyword Number String",
  maxTerm: 17,
  nodeProps: [
    ["isolate", -3,1,2,11,""],
    ["openedBy", 4,"("],
    ["closedBy", 5,")"],
    ["group", -6,6,7,8,9,10,11,"Expression"]
  ],
  skippedNodes: [0,1,2],
  repeatNodeCount: 1,
  tokenData: "0o~R^XY}YZ}]^}pq}rs!Stu#pxy'Uyz(e{|(j}!O(j!Q!R(s!R![*p!]!^.^#T#o.{~!SO_~~!VVOr!Srs!ls#O!S#O#P!q#P;'S!S;'S;=`#j<%lO!S~!qOZ~~!tRO;'S!S;'S;=`!};=`O!S~#QWOr!Srs!ls#O!S#O#P!q#P;'S!S;'S;=`#j;=`<%l!S<%lO!S~#mP;=`<%l!S~#siqr%bst%btu%buv%bvw%bwx%bz{%b{|%b}!O%b!O!P%b!P!Q%b!Q![%b![!]%b!^!_%b!_!`%b!`!a%b!a!b%b!b!c%b!c!}%b#Q#R%b#R#S%b#S#T%b#T#o%b#p#q%b#r#s%b~%giV~qr%bst%btu%buv%bvw%bwx%bz{%b{|%b}!O%b!O!P%b!P!Q%b!Q![%b![!]%b!^!_%b!_!`%b!`!a%b!a!b%b!b!c%b!c!}%b#Q#R%b#R#S%b#S#T%b#T#o%b#p#q%b#r#s%b~'ZPT~!]!^'^~'aTO!]'^!]!^'p!^;'S'^;'S;=`(_<%lO'^~'sVOy'^yz(Yz!]'^!]!^'p!^;'S'^;'S;=`(_<%lO'^~(_OQ~~(bP;=`<%l'^~(jOS~~(mQ!Q!R(s!R![*p~(xUY~!O!P)[!Q![*p!g!h){#R#S+U#X#Y){#l#m+[~)aRY~!Q![)j!g!h){#X#Y){~)oSY~!Q![)j!g!h){#R#S*j#X#Y){~*OR{|*X}!O*X!Q![*_~*[P!Q![*_~*dQY~!Q![*_#R#S*X~*mP!Q![)j~*uTY~!O!P)[!Q![*p!g!h){#R#S+U#X#Y){~+XP!Q![*p~+_R!Q![+h!c!i+h#T#Z+h~+mVY~!O!P,S!Q![+h!c!i+h!r!s-P#R#S+[#T#Z+h#d#e-P~,XTY~!Q![,h!c!i,h!r!s-P#T#Z,h#d#e-P~,mUY~!Q![,h!c!i,h!r!s-P#R#S.Q#T#Z,h#d#e-P~-ST{|-c}!O-c!Q![-o!c!i-o#T#Z-o~-fR!Q![-o!c!i-o#T#Z-o~-tSY~!Q![-o!c!i-o#R#S-c#T#Z-o~.TR!Q![,h!c!i,h#T#Z,h~.aP!]!^.d~.iSP~OY.dZ;'S.d;'S;=`.u<%lO.d~.xP;=`<%l.d~/QiX~qr.{st.{tu.{uv.{vw.{wx.{z{.{{|.{}!O.{!O!P.{!P!Q.{!Q![.{![!].{!^!_.{!_!`.{!`!a.{!a!b.{!b!c.{!c!}.{#Q#R.{#R#S.{#S#T.{#T#o.{#p#q.{#r#s.{",
  tokenizers: [0],
  topRules: {"Module":[0,3]},
  specialized: [{term: 9, get: (value) => spec_Keyword[value] || -1}],
  tokenPrec: 0
});

const wastLanguage = /*@__PURE__*/LRLanguage.define({
    name: "wast",
    parser: /*@__PURE__*/parser.configure({
        props: [
            /*@__PURE__*/indentNodeProp.add({
                App: /*@__PURE__*/delimitedIndent({ closing: ")", align: false })
            }),
            /*@__PURE__*/foldNodeProp.add({
                App: foldInside,
                BlockComment(tree) { return { from: tree.from + 2, to: tree.to - 2 }; }
            }),
            /*@__PURE__*/styleTags({
                Keyword: tags.keyword,
                Type: tags.typeName,
                Number: tags.number,
                String: tags.string,
                Identifier: tags.variableName,
                LineComment: tags.lineComment,
                BlockComment: tags.blockComment,
                "( )": tags.paren
            })
        ]
    }),
    languageData: {
        commentTokens: { line: ";;", block: { open: "(;", close: ";)" } },
        closeBrackets: { brackets: ["(", '"'] }
    }
});
function wast() {
    return new LanguageSupport(wastLanguage);
}

export { wast, wastLanguage };
